<template>
  <div class="mobile-menu brand-white-bg">
    <!-- TOP ROW -->
    <div class="top-row container px-2 px-sm-3 px-md-4 px-xl-5">
      <div class="nav-content w-100 mx-auto">
        <!-- APP LOGO  -->
        <router-link to="/" class="app-logo">
          <img v-lazy="staticAsset('Class54Logo.svg')" alt="class54" />
        </router-link>

        <!-- MENU TOGGLE  -->
        <div class="menu-toggler smooth-transition" @click="$emit('closeMenu')">
          <div
            class="icon icon-close brand-dark-blue smooth-transition pointer"
            title="Menu"
          ></div>
        </div>
      </div>
    </div>

    <!-- MENU ROW -->
    <div class="bottom-row container px-3 px-md-4 px-xl-5">
      <div class="nav-items">
        <!-- ABOUT LINK  -->
        <router-link to="/about" class="item w-100 py-1">About</router-link>
        <!-- DOWNLOADS LINK  -->
        <div class="item pointer w-100 py-1">
          <div
            class="
              item-wrapper
              d-flex
              justify-content-start
              align-items-center
              w-100
            "
            @click="toggleMobileDropdown"
          >
            <div class="text">Downloads</div>
            <div
              class="icon icon-caret-down"
              :class="{ 'rotate-180': show_mobile_dropdown }"
            ></div>
          </div>

          <!-- NAV DROPDOWN  -->
          <div
            class="
              nav-dropdown
              rounded-4
              brand-white-bg
              index-1
              smooth-animation
            "
            v-if="show_mobile_dropdown"
          >
            <div class="inner-wrapper position-relative w-100 h-auto">
              <!-- ANDROID  -->
              <router-link
                to
                @click.native="
                  openMobileApp(
                    'https://play.google.com/store/apps/details?id=com.class54.mobile'
                  )
                "
                class="nav-item"
              >
                <div class="avatar">
                  <div class="icon icon-android"></div>
                </div>

                <div>
                  <div class="title">Android</div>
                  <div class="value">
                    Lightweight and fast for all your prep needs
                  </div>
                </div>
              </router-link>

              <!-- IOS  -->
              <router-link
                to
                @click.native="
                  openMobileApp(
                    'https://apps.apple.com/us/app/class54/id1622971742'
                  )
                "
                class="nav-item"
              >
                <div class="avatar">
                  <div class="icon icon-apple"></div>
                </div>

                <div>
                  <div class="title">IOS</div>
                  <div class="value">
                    Lightweight and fast for all your prep needs
                  </div>
                </div>
              </router-link>

              <!-- DESKTOP  -->
              <router-link to="/jamb-software-download" class="nav-item">
                <div class="avatar">
                  <div class="icon icon-windows-angle"></div>
                </div>

                <div>
                  <div class="title">Desktop (windows)</div>
                  <div class="value">
                    Exam scenario offline on your computer
                  </div>
                </div>
              </router-link>
            </div>
          </div>
        </div>

        <!-- BLOG LINK  -->
        <a
          href="https://medium.com/@class54edu"
          target="_blank"
          class="item w-100 py-1"
          >Blog</a
        >

        <!-- ACTIVATE DESKTOP LINK  -->
        <router-link to="/activate-desktop" class="item w-100 py-1"
          >Activate Desktop</router-link
        >

        <!-- LOGIN BUTTON  -->
        <router-link to @click.native="processAuth" class="btn btn-outline">{{
          getAuthToken ? "Log Out" : "Login"
        }}</router-link>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters, mapActions } from "vuex";

export default {
  name: "MobileMenu",

  computed: {
    ...mapGetters({ getAuthToken: "auth/getAuthToken" }),
  },

  data: () => ({
    show_mobile_dropdown: false,
  }),

  methods: {
    ...mapActions({ logoutUser: "auth/logoutUser" }),

    toggleMobileDropdown() {
      this.show_mobile_dropdown = !this.show_mobile_dropdown;
    },

    openMobileApp(url) {
      let link = document.createElement("a");
      link.setAttribute("href", url);
      link.setAttribute("target", "_blank");
      link.click();
    },

    logOut() {
      this.$bus.$emit("show-page-loader", "Logging out");

      this.logoutUser()
        .then((response) => {
          if (response.data.status_code == 200) {
            setTimeout(() => {
              location.href = "/select-subscription";
            }, 2000);
          }
        })
        .catch(() => {
          localStorage.clear();
          location.href = "/select-subscription";
        });
    },

    processAuth() {
      if (this.getAuthToken) this.logOut();
      else this.$router.push("/login");
    },
  },
};
</script>

<style lang="scss" scoped>
@keyframes slide-left {
  0%,
  30% {
    margin-left: -50px;
    opacity: 0;
  }

  to {
    opacity: 1;
  }
}

.mobile-menu {
  @include fixed-display-area;
  animation: slide-left 0.7s ease-in-out forwards;

  .top-row {
    padding: toRem(24) 0;
    @include transition(0.3s);

    .nav-content {
      @include flex-row-between-nowrap;

      .app-logo {
        img {
          @include breakpoint-down(md) {
            @include rectangle-shape(140, 30);
            left: toRem(-6);
          }

          @include breakpoint-down(sm) {
            @include rectangle-shape(130, 27);
          }
        }
      }

      .menu-toggler {
        .icon {
          font-size: toRem(30);
        }
      }
    }
  }

  .bottom-row {
    margin-top: toRem(40);

    @include breakpoint-down(xs) {
      margin-top: toRem(20);
    }

    .nav-items {
      @include flex-column-start-start;
      padding-left: toRem(5);

      .item {
        position: relative;
        @include font-height(21, 26);
        margin: toRem(18) 0;
        color: $dark-text;
        font-weight: 700;
        width: max-content;

        @include breakpoint-down(sm) {
          @include font-height(18, 24);
        }

        @include breakpoint-down(xs) {
          @include font-height(17, 22);
        }

        .icon {
          @include transition(0.5s);
          margin-left: toRem(6);
          font-size: toRem(21);
        }

        .nav-dropdown {
          position: relative;
          padding: toRem(9) toRem(8);
          top: toRem(13);
          height: auto;
          left: 0;
          width: toRem(305);
          box-shadow: 0 toRem(18) toRem(80) rgba($brand-black, 0.04),
            0 toRem(11.6667) toRem(46.8519) rgba($brand-black, 0.0303704),
            0 toRem(6.93333) toRem(25.4815) rgba($brand-black, 0.0242963),
            0 toRem(3.6) toRem(13) rgba($brand-black, 0.02),
            0 toRem(1.46667) toRem(6.51852) rgba($brand-black, 0.0157037),
            0 toRem(0.333333) toRem(3.14815) rgba($brand-black, 0.00962963);

          .nav-item {
            @include flex-row-start-nowrap;
            align-items: flex-start;
            padding: toRem(8) toRem(14);
            border-radius: toRem(4);
            @include transition(0.4s);

            .avatar {
              background: $brand-sky-blue;
              @include square-shape(28);
              margin-right: toRem(10);

              .icon {
                @include center-y;
                font-size: toRem(16.5);
                color: $brand-dark-green;
              }
            }

            .title {
              @include font-height(13.25, 19);
              color: $blackish-grey;
              margin-bottom: toRem(4);
              font-weight: 700;
            }

            .value {
              @include font-height(11.45, 16);
              color: $blackish-grey;
              font-weight: normal;
            }

            &:hover {
              background: #f2f2f2;

              .title {
                text-decoration: underline;
              }
            }
          }
        }
      }

      .btn {
        margin-top: toRem(30);
        font-size: toRem(14);

        @include breakpoint-down(md) {
          width: 50%;
        }

        @include breakpoint-down(sm) {
          width: 100%;
        }
      }
    }
  }
}
</style>
